@import 'variables.css';
.home-banner {
    position: relative;
}
.bgImgage-GradientHomePage::after {
    position: absolute;
    inset: 0;
    content: '';
    background-image: linear-gradient(rgba(255, 255, 255, 0) 35%, #000000);
}

.bgImgage-Gradient::after {
    position: absolute;
    inset: 0;
    content: '';
    background-image: linear-gradient(rgba(255, 255, 255, 0) 35%, #000000);
}

.home-banner-sub {
    position: absolute;
    left: 7%;
    right: 7%;
}

.home-banner-sub > .home-banner-title {
    font-weight: 700;
    line-height: 58.09px;
}

.home-banner-group > .btn {
    padding: 12px 24px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
}

/* home body */

.container-or {
    width: 70%;
}

.home__list-news {
    position: relative;
}

.home__sub-news {
    position: absolute;
    width: 80%;
    bottom: 60px;
    left: 24px;
}

.home__sub-news:first-child {
    line-height: 2.5rem;
}

.home__body-item-line {
    width: 120px;
    height: 10px;
}

@media (min-width: 1536px) and (max-width: 1919px) {
    .home__body-item-line {
        width: 120px;
        height: 8px;
    }
}

@media (min-width: 1366px) and (max-width: 1535px) {
    .home__body-item-line {
        width: 120px;
        height: 8px;
    }
}

.home__body-item-line > div {
    flex: 1;
}

.home__body-item-line > div:first-child {
    background-color: #ededed;
}
.home__body-item-line > div:nth-child(2) {
    background-color: var(--primary);
    opacity: 0.5;
}
.home__body-item-line > div:last-child {
    background-color: var(--primary);
}

.btn-see_more {
    padding: 12px;
    display: flex;
    align-items: center;
    border: 1px solid var(--light-blue);
}

/* calendar */

.calender-item > div > span {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100rem;
    margin: 2px;
    width: 40px;
    height: 40px;
    color: #000;
}

.calender-item > div > span.current-day {
    border: 1px solid var(--light-blue);
}

.calender-item > div > span:hover:not(.current-day, .active) {
    background-color: var(--light-blue);
    transition: all 0.25s linear;
    color: #ffffff;
}

.calender-item > div > span.active {
    background-color: var(--light-blue);
    color: #fff;
}

.calender-item > div > span.unActive {
    color: rgba(0, 0, 0, 0.3);
}
.calendar-option {
    width: 80px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #cecece;
    border-radius: 8px;
    position: relative;
}

.calendar-option > span {
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar-option:after {
    content: '';
    position: absolute;
    display: block;
    background-color: rgba(99, 99, 99, 0.1);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2px;
    height: 50%;
}

.form_add-event {
    position: absolute;
    top: 45px;
    right: -50px;
    border-radius: 5px;
}

.form_add-event > input {
    flex: 1;
}

.drop-list_calender {
    position: absolute;
    right: 0;
    top: 45px;
    background-color: white;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    transform: scale(0);
}

.drop-list_calender.active {
    transform-origin: calc(100% - 32px) top;
    animation: scaleX linear 0.25s forwards;
}

@keyframes scaleX {
    from {
        transform: scale(0);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

.calender_item-drop {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 8px;

    outline: none;
    border: none;
}

.calender_item-drop::-webkit-scrollbar {
    display: none;
}

.calender_item-drop > li {
    padding: 12px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: #ccc;
}

.calender_item-drop > li.active {
    background-color: #f5f5f5;
    color: #000;
    border-radius: 4px;
}

@media screen and (max-width: 768px) {
    .home-banner-sub {
        bottom: 10%;
        left: 5%;
        right: 5%;
    }
    .home-banner-sub > .home-banner-title {
        font-size: 35px;
    }
}
@media screen and (max-width: 600px) {
    .home-banner-sub {
        bottom: 8%;
    }
    .home-banner-group > .btn {
        max-height: 35px;
        padding: 6px 16px;
        font-size: 16px;
    }
    .home-banner-sub > .home-banner-title {
        line-height: 30px;
        font-size: 30px;
        margin-bottom: 12px;
    }
    .home-banner-sub > .home-banner-subtitle {
        font-size: 22px;
        margin-bottom: 24px;
    }
}
@media screen and (max-width: 480px) {
    .home-banner-sub {
        bottom: 8%;
        left: 5%;
        right: 5%;
    }
    .home-banner-group > .btn {
        max-height: 35px;
        padding: 6px 16px;
        font-size: 13px;
    }
    .home-banner-sub > .home-banner-title {
        line-height: 25px;
        font-size: 20px;
        margin-bottom: 10px;
    }
    .home-banner-sub > .home-banner-subtitle {
        font-size: 20px;
        margin-bottom: 15px;
    }
}
@media screen and (max-width: 375px) {
    .home-banner-sub {
        bottom: 1%;
    }
    .home-banner-group > .btn {
        max-height: 35px;
        padding: 5px 12px;
        font-size: 10px;
    }
    .home-banner-sub > .home-banner-title {
        line-height: 10px;
        font-size: 16px;
        margin-bottom: 12px;
    }
    .home-banner-sub > .home-banner-subtitle {
        font-size: 16px;
        margin-bottom: 12px;
        font-weight: bold;
    }
}
@media screen and (max-width: 320px) {
    .home-banner-sub > .home-banner-title {
        line-height: 10px;
        font-size: 15px;
        margin: 0;
    }
    .home-banner-sub > .home-banner-subtitle {
        font-size: 15px;
        margin-bottom: 5px;
        font-weight: bold;
    }
}
