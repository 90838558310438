/* @import 'antd/dist/antd.min.css'; */
/* reset css*/

* {
  box-sizing: border-box !important;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
/* em, */
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: '#373737';
}

code {
  font-family: 'Inter';
}

.lms-container {
  /* overflow-y: auto;
  overflow-x: auto; */
  width: 100%;
  /* height: 100vh; */
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.hiddenScroll::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb {
  width: 6px;
  background-color: #a8afbf;
}

.lms-container-horizontal::-webkit-scrollbar-thumb:horizontal {
  height: 2px;
  background-color: #a8afbf;
}

.lms-container-horizontal::-webkit-scrollbar {
  height: 3px;
}

.hex {
  display: block;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  -webkit-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
  -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.flt_svg {
  visibility: hidden;
  position: absolute;
  width: 0px;
  height: 0px;
}

ul li {
  list-style-type: disc;
}

ol li {
  list-style-type: decimal;
}

.box_parent {
  filter: url('#flt_tag');
}

.text-normal {
  font-size: 15px;
  line-height: 18.5px;
}

@import url('./variables.css');
@import url('./ckeditor.css');