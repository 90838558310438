@import url('./variables.css');

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(125, 125, 125, 0.3);
  padding: 22px;
  border-radius: 10px;
  margin-bottom: 21px;
  background: #ffffff;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}

.btn {
  border: 1px solid rgb(105, 105, 105);
  height: 50px;
  border-radius: 10px;
  padding-left: 21px;
  padding-right: 21px;
  font-size: 18px;
  line-height: 22px;
}

.text-input {
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  height: 50px;
  padding-left: 21px;
  padding-right: 21px;
}

.input-title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  height: 33px;
  display: inline-block;
}

.input-title>span {
  color: var(--red);
}

/* custom checkbox */
.checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 2px solid rgb(105, 105, 105);
  border-radius: 100%;
}

.checkmark::after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox input:checked~.checkmark::after {
  display: block;
}

.checkbox .checkmark:after {
  left: 50%;
  top: 50%;
  width: 80%;
  height: 80%;
  transform: translate(-50%, -50%);
  background-color: rgb(105, 105, 105);
  border-radius: 100%;
}

.text-green {
  color: var(--green);
}

.bg-lightpink {
  background-color: var(--light-pink);
}

.bg-lightgray {
  background-color: var(--light-gray);
}

.side_bar_hidden {
  animation: sliderShow 0.2s linear forwards;
}

@keyframes sliderShow {
  from {
    width: 4.5vw;
  }

  to {
    width: 14.6vw;
  }
}

.unactive_child {
  display: contents;
}

.active_child {
  display: none;
}

.active-link>.unactive_child {
  display: none;
}

.active-link>.active_child {
  display: contents;
}

.active-link>span {
  color: var(--text-secondary);
}

.jsx-parser {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* width */
.hideScroll::-webkit-scrollbar {
  width: 1px !important;
  max-height: 5px;
}

/* Track */
.hideScroll::-webkit-scrollbar-track {
  background: #f5f5f5 !important;
  border-radius: 10px !important;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}

/* Handle */
.hideScroll::-webkit-scrollbar-thumb {
  background: #6363634D;
  border-radius: 10px;
}

.student_list-item:hover {
  background-color: rgba(36, 222, 235, 0.514);
}

.arrow_student {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  display: none;
}

.student_list-item:hover .arrow_student {
  display: block;
}

@media (min-width: 1536px) {
  .side_bar_hidden {
    width: 287px !important;
  }
}

@media (min-width: 1365px) and (max-width: 1535px) {
  .side_bar_hidden {
    width: 250px !important;
  }
}

.question-name {
  position: relative;
}

.question-name-hide {
  position: absolute;
  bottom: 20;
  width: fit-content;
  display: none;
  padding: 4px;
}

.question-name:hover .question-name-hide {
  display: block;
  background-color: #fff;
  border: 1px solid #eee;
  z-index: 10000;
}

.scroll-bar-custom::-webkit-scrollbar {
  width: 8px;
}

.scroll-bar-custom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.scroll-bar-custom::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.outline-none:focus-visible {
  outline: none !important;
}

textarea {
  resize: none !important;
}

.footer-animation-list {
  height: calc(var(--footer-size) * var(--height-li));
  transition: all ease 0.3s;
}

.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  min-height: 500px;
}

.group-editor-discuss .ck-content {
  max-height: 150px !important;
}

.group-editor-discuss .ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  min-height: 150px !important;
  max-height: 150px !important;
}