@import url('./manager.css');

.manager_table {
  border-bottom: 1px solid #6363631a;
}

.manager_table>thead>tr>td {
  color: var(--white);
}

.manager_table>tbody>tr>td {
  color: var(--text-48);
}

.manager_table>thead>tr>td,
.manager_table>tbody>tr>td {
  padding: 24px 12px;
  border-left: 1px solid rgba(99, 99, 99, 0.1);
  border-right: 1px solid rgba(99, 99, 99, 0.1);
  font-size: 18px;
  vertical-align: middle;
}

.manager_table>thead>tr:nth-child(even),
.manager_table>tbody>tr:nth-child(odd) {
  background-color: var(--bg-e5f3f8);
}

.manager_table>thead {
  background-color: var(--thead-table);
}

/* .manager_table > thead > tr > td:first-child {
    width: 50px;
} */

.checkbox-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 18px !important;
  height: 18px !important;

  outline: 2px solid #ccc;
  border-radius: 2px;
  background: #fff;
  margin: 0 auto;
}

.checkbox-item>span {
  font-size: 18px;
  font-weight: 600;
  opacity: 0;
  color: var(--light_blue);
}

input[type='checkbox']:checked~.checkbox-item {
  outline: 3px solid var(--light_blue);
}

input[type='checkbox']:checked~.checkbox-item>span {
  opacity: 1;
}

.checkbox-custom {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-custom>span {
  display: none;
}

.checkbox-custom>input[type='checkbox']:checked~span {
  display: block;
}

.lms-pagination>* {
  width: 20px;
  height: 20px;
  color: var(--text-48);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.activeLink {
  background: var(--bg-e5f3f8);
}

.text_area {
  width: 100%;
  height: 106px;
  border-radius: 4px;
  overflow: hidden;
  border-color: rgba(125, 125, 125, 0.3);
  padding: 0 4px;
}

.custom_select {
  position: relative;
}

.text-editor {
  height: 438px;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
  /* border: 1px solid rgba(125, 125, 125, 0.3); */
}

.label-radio {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 100rem;
  border: 3px solid #dadada;
}

.label-radio::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 50%;
  height: 50%;
  border-radius: 100rem;
  background-color: #dadada;
  transition: all 0.2 linear;
  display: none;
  /* transform: scale(0); */
}

input[type='radio']:checked~.label-radio::after {
  display: block;
}

.display_none {
  display: none !important;
}

.modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 90;
}

.lms-time-duration-video {
  position: absolute;
  bottom: 0;
  right: 0;

  color: #000;

  width: 43px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.lms-time-duration-video::after {
  position: absolute;
  content: '';
  display: block;
  inset: 0;
  background-color: #999;
  border-top-left-radius: 5px;
}

.lms-time-duration-video.list {
  color: #fff;
}

.lms-time-duration-video.list::after {
  background: #636363;
  mix-blend-mode: multiply;
  opacity: 0.7;
}

.radio-custom {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-custom>span {
  display: none;
}

/* .radio-custom > input[type='radio']:checked ~ span {
  display: block;
  color: blue;
} */
.radio-custom>input[type='radio']:checked~div {
  /* background-color: var(--text-white); */
  border-color: var(--primary);
}

.radio-custom>input[type='radio']:checked~div::after {
  content: '';
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--primary);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}