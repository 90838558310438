:root {
  /* define global variable */
  --light_blue: #0d2a5a;
  --green: var(--light-blue);
  --primary: #00a8b5;
  --thead-table: #059dce;
  --white: #fff;
  --light-green: #5dab68;
  --light-blue: #00a8b5;
  --green-bold: #1d4f24;
  --blue-bold: #014f59;
  --blue-heavy: #0d2a5a;
  --light-gray: rgba(181, 181, 181, 0.1);
  --gray: #636363;
  --light-pink: #fff9f9;
  --text-color: #727272;
  --red: #ff4a4f;
  --text-48: #484848;
  --bg-e5f3f8: #e5f3f8;
  --text-secondary: '#1C7988';
}
