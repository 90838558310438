/* @import url(index.css); */
@import url('./variables.css');
@import url(./Home.css);
@import url(./Common.css);
@import url(./ConfigQuill.css);
@import url(./Layout.css);
@import url(./manager.css);
/* @import "@fontsource/inter"; */
@tailwind base;
@layer base {
    h1 {
        @apply text-2xl;
    }
    h2 {
        @apply text-xl;
    }
    h3 {
        @apply text-lg;
    }
    a {
        @apply text-blue-600;
    }
}
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('../../public/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../../public/fonts/Roboto-Regular.ttf') format('truetype');
}
body {
    color: var(--gray);
    font-family: Inter;
    overflow-x: hidden !important;
}

pre {
    word-break: break-word;
}

.over-hidden::-webkit-scrollbar {
    display: none;
}
.notify::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}
.after-tootip::after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 50%;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #d0d0d0 transparent;
}

input[type="input"],
textarea {
    padding-left: 20px !important;
}
